@charset "utf-8";

//variables
@import './common/variables';

//lib
@import '../lib/bootstrap-4.5.3/scss/bootstrap-grid.scss';
@import '../lib/bootstrap-4.5.3/scss/mixins';
@import '../lib/bootstrap-4.5.3/scss/utilities/text';
@import '../lib/slick/slick.scss';
@import '../lib/slick/slick-theme.scss';
@import '../lib/aos/css/aos.css';

//commons
@import './common/functions';
@import './common/mixin';
@import './common/fonts';

@import './common/resettheme';

//votre-sejour-components
@import './components/main';
@import './components/header';
@import './components/footer';
@import './components/classes';
@import './components/buttons';
@import './components/megamenu';
@import './components/bulle';
@import './components/glanum';

//pages
@import './pages/home';
