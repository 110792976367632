.gm-navbar {
  ul {
    margin: 0 !important;
    padding: 0 !important;
    li {
      margin: 0 !important;
      padding: 0 !important;
      &::before {
        display: none !important;
      }
    }
  }
}

.officeMenu {
  &.gm-navbar-sticky-toggle {
    .gm-wrapper {
      .gm-logo {
        padding: 0 !important;
        margin-bottom: 0 !important;
        img {
          padding: 4px 0 4px 0 !important;
        }
      }
    }
  }

  .gm-wrapper {
    background-color: $white;

    .gm-actions {
      position: absolute;
      display: block;
      right: 0;
      top: -25px;
      padding: 0;

      i {
        font-size: 25px;
        min-width: 25px;
        text-align: center;
        color: $primary;
        transition: opacity ease-in-out 0.2s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .gm-toolbar {
      padding-top: 12px;
      &-socials-list {
        margin: 0 !important;

        @include media-breakpoint-up(md) {
          margin: 0 15px 0 0 !important;
        }

        @include media-breakpoint-up(lg) {
          margin: 0 30px 0 0 !important;
        }

        li {
          margin: 0 4px 0 4px !important;

          &:first-child {
            margin-left: 0 !important;
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }

    .gm-logo {
      padding: 0 !important;
      margin-bottom: 0 !important;
      transition: opacity ease-in-out 0.2s;

      &:hover {
        opacity: 0.6;
      }

      @include media-breakpoint-up(md) {
        padding: 5px;
        margin-bottom: 20px !important;
        margin-right: 15px;
        margin-left: 30px;
      }

      img {
        padding: 4px 0 4px 0 !important;
      }
    }

    .gm-menu-btn {
      @include media-breakpoint-up(md) {
        margin-right: 15px;
      }

      i {
        color: $secondary;
      }

      .gm-menu-btn__inner {
        font-size: 39px;
        transition: opacity ease-in-out 0.2s;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .gm-navbar-nav {
      .gm-menu-item--lvl-0 {
        margin: 0 12px 0 12px !important;

        @include media-breakpoint-up(lg) {
          &:first-child {
            margin-left: 0 !important;
          }

          &:last-child {
            margin-right: 0 !important;
          }

          .menu-item {
            .gm-menu-item__txt {
              font-weight: 400;
            }
          }
        }

        .gm-menu-item__txt {
          font-weight: 500;
        }
      }

      .salon-link {
        .gm-menu-item__txt {
          color: #075e9c;
        }
      }
    }

    .gm-dropdown-menu {
      padding: 30px !important;
    }

    .gm-main-menu-wrapper .mega-gm-dropdown .gm-dropdown-menu-wrapper {
      margin: 3px 0 0 auto !important;
    }

    .gm-main-menu-wrapper .gm-menu-item__link {
      padding: 6px 0 6px 0;
    }

    .gm-mega-menu__item__title {
      font-weight: 700 !important;
      .gm-menu-item__txt {
        font-weight: 700 !important;
        @include font-size(14px);
      }
    }

    .gm-inner {
      box-shadow: none !important;
      border-bottom: 1px solid #e2e0e2 !important;
    }
  }

  .contactUsTxt {
    font-family: $Montserrat;
    @include font-size(14px);
    text-transform: uppercase;
    color: #4b4b4b;
    margin-right: 15px;
  }
}

.menu-item {
  .gm-dropdown-menu-wrapper {
    .gm-anchor,
    .gm-menu-item__link {
      border-bottom: none !important;
    }
  }
}

.gm-navigation-drawer--mobile.gm-navigation-drawer--right {
  transform: translate3d(320px, 0, 0) !important;
  right: 0 !important;
}

.gm-navigation-drawer--mobile {
  width: 320px !important;
  max-width: 90% !important;
  font-size: 16px !important;

  ul li,
  ol li,
  .gm-menu-item__txt {
    font-size: 16px !important;
  }

  .gm-mobile-action-area-wrapper {
    order: -1;
  }

  .gm-search {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    border-radius: 30px;
    padding: 8px 8px 8px 16px;
    width: 180px;
    max-width: 100%;
    white-space: nowrap;
    transition: opacity ease-in-out 0.2s;

    &:hover {
      opacity: 0.6;
    }

    i {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 40px;
      margin: 0 !important;
      font-size: 25px !important;
      color: $white;
      transition: opacity ease-in-out 0.2s;

      &:hover {
        opacity: 0.6;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .gm-search__txt {
      //display: none !important;
      color: $white;
      margin: 0 0 0 10px !important;
      font-family: $Roboto;
      font-weight: 500;
      font-size: 16px !important;
    }
  }

  .gm-anchor,
  .gm-mega-menu__item__title {
    border-bottom: 1px solid $primary !important;
  }
}

.gm-navbar.gm-navbar--style-2 .gm-main-menu-wrapper:not(.gm-navigation-drawer--open) {
  display: none;
}

.gm-navigation-drawer.gm-navigation-drawer--open {
  transform: translate3d(0, 0, 0) !important;
}
.wrap__breadcrumb {
  margin: 30px 0 0 0 !important;
  border: 0 !important;
  padding-left: 0 !important;
}

.gm-search__alpha {
  color: $white !important;
}

.gm-search__input {
  color: $white !important;
}
