.slick-slider {
  margin-bottom: 90px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.slick-dots {
  li {
    button {
      &::before {
        @include font-size(70px);
        color: #8b8b8b;
      }
    }
  }
}
