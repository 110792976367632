@charset "UTF-8";
/*! Bootstrap Grid v4.5.3 (https://getbootstrap.com/) Copyright 2011-2020 The Bootstrap Authors Copyright 2011-2020 Twitter, Inc. Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE) */
@import url(../lib/aos/css/aos.css);
html { box-sizing: border-box; -ms-overflow-style: scrollbar; }

*, *::before, *::after { box-sizing: inherit; }

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

@media (min-width: 576px) { .container, .container-sm { max-width: 540px; } }

@media (min-width: 768px) { .container, .container-sm, .container-md { max-width: 720px; } }

@media (min-width: 992px) { .container, .container-sm, .container-md, .container-lg { max-width: 960px; } }

@media (min-width: 1200px) { .container, .container-sm, .container-md, .container-lg, .container-xl { max-width: 1280px; } }

.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.row-cols-1 > * { flex: 0 0 100%; max-width: 100%; }

.row-cols-2 > * { flex: 0 0 50%; max-width: 50%; }

.row-cols-3 > * { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }

.row-cols-4 > * { flex: 0 0 25%; max-width: 25%; }

.row-cols-5 > * { flex: 0 0 20%; max-width: 20%; }

.row-cols-6 > * { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }

.col-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }

.col-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }

.col-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }

.col-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }

.col-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.order-first { order: -1; }

.order-last { order: 13; }

.order-0 { order: 0; }

.order-1 { order: 1; }

.order-2 { order: 2; }

.order-3 { order: 3; }

.order-4 { order: 4; }

.order-5 { order: 5; }

.order-6 { order: 6; }

.order-7 { order: 7; }

.order-8 { order: 8; }

.order-9 { order: 9; }

.order-10 { order: 10; }

.order-11 { order: 11; }

.order-12 { order: 12; }

.offset-1 { margin-left: 8.3333333333%; }

.offset-2 { margin-left: 16.6666666667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.3333333333%; }

.offset-5 { margin-left: 41.6666666667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.3333333333%; }

.offset-8 { margin-left: 66.6666666667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.3333333333%; }

.offset-11 { margin-left: 91.6666666667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-sm-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-sm-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-sm-3 > * { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .row-cols-sm-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-sm-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-sm-6 > * { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-sm-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-sm-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-sm-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-sm-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.3333333333%; }
  .offset-sm-2 { margin-left: 16.6666666667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.3333333333%; }
  .offset-sm-5 { margin-left: 41.6666666667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.3333333333%; }
  .offset-sm-8 { margin-left: 66.6666666667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.3333333333%; }
  .offset-sm-11 { margin-left: 91.6666666667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-md-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-md-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-md-3 > * { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .row-cols-md-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-md-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-md-6 > * { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-md-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-md-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-md-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-md-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.3333333333%; }
  .offset-md-2 { margin-left: 16.6666666667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.3333333333%; }
  .offset-md-5 { margin-left: 41.6666666667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.3333333333%; }
  .offset-md-8 { margin-left: 66.6666666667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.3333333333%; }
  .offset-md-11 { margin-left: 91.6666666667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-lg-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-lg-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-lg-3 > * { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .row-cols-lg-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-lg-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-lg-6 > * { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-lg-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-lg-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-lg-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-lg-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.3333333333%; }
  .offset-lg-2 { margin-left: 16.6666666667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.3333333333%; }
  .offset-lg-5 { margin-left: 41.6666666667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.3333333333%; }
  .offset-lg-8 { margin-left: 66.6666666667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.3333333333%; }
  .offset-lg-11 { margin-left: 91.6666666667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-xl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-xl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-xl-3 > * { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .row-cols-xl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-xl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-xl-6 > * { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-xl-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-xl-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-xl-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-xl-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.3333333333%; }
  .offset-xl-2 { margin-left: 16.6666666667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.3333333333%; }
  .offset-xl-5 { margin-left: 41.6666666667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.3333333333%; }
  .offset-xl-8 { margin-left: 66.6666666667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.3333333333%; }
  .offset-xl-11 { margin-left: 91.6666666667%; } }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }

@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }

@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }

@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }

@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-n1 { margin: -0.25rem !important; }

.mt-n1, .my-n1 { margin-top: -0.25rem !important; }

.mr-n1, .mx-n1 { margin-right: -0.25rem !important; }

.mb-n1, .my-n1 { margin-bottom: -0.25rem !important; }

.ml-n1, .mx-n1 { margin-left: -0.25rem !important; }

.m-n2 { margin: -0.5rem !important; }

.mt-n2, .my-n2 { margin-top: -0.5rem !important; }

.mr-n2, .mx-n2 { margin-right: -0.5rem !important; }

.mb-n2, .my-n2 { margin-bottom: -0.5rem !important; }

.ml-n2, .mx-n2 { margin-left: -0.5rem !important; }

.m-n3 { margin: -1rem !important; }

.mt-n3, .my-n3 { margin-top: -1rem !important; }

.mr-n3, .mx-n3 { margin-right: -1rem !important; }

.mb-n3, .my-n3 { margin-bottom: -1rem !important; }

.ml-n3, .mx-n3 { margin-left: -1rem !important; }

.m-n4 { margin: -1.5rem !important; }

.mt-n4, .my-n4 { margin-top: -1.5rem !important; }

.mr-n4, .mx-n4 { margin-right: -1.5rem !important; }

.mb-n4, .my-n4 { margin-bottom: -1.5rem !important; }

.ml-n4, .mx-n4 { margin-left: -1.5rem !important; }

.m-n5 { margin: -3rem !important; }

.mt-n5, .my-n5 { margin-top: -3rem !important; }

.mr-n5, .mx-n5 { margin-right: -3rem !important; }

.mb-n5, .my-n5 { margin-bottom: -3rem !important; }

.ml-n5, .mx-n5 { margin-left: -3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-n1 { margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 { margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 { margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 { margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 { margin-left: -0.25rem !important; }
  .m-sm-n2 { margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 { margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 { margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 { margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 { margin-left: -0.5rem !important; }
  .m-sm-n3 { margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 { margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 { margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 { margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 { margin-left: -1rem !important; }
  .m-sm-n4 { margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 { margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 { margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 { margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 { margin-left: -1.5rem !important; }
  .m-sm-n5 { margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 { margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 { margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 { margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 { margin-left: -3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }

@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-n1 { margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 { margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 { margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 { margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 { margin-left: -0.25rem !important; }
  .m-md-n2 { margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 { margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 { margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 { margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 { margin-left: -0.5rem !important; }
  .m-md-n3 { margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 { margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 { margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 { margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 { margin-left: -1rem !important; }
  .m-md-n4 { margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 { margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 { margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 { margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 { margin-left: -1.5rem !important; }
  .m-md-n5 { margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 { margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 { margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 { margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 { margin-left: -3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }

@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-n1 { margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 { margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 { margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 { margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 { margin-left: -0.25rem !important; }
  .m-lg-n2 { margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 { margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 { margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 { margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 { margin-left: -0.5rem !important; }
  .m-lg-n3 { margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 { margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 { margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 { margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 { margin-left: -1rem !important; }
  .m-lg-n4 { margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 { margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 { margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 { margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 { margin-left: -1.5rem !important; }
  .m-lg-n5 { margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 { margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 { margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 { margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 { margin-left: -3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }

@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-n1 { margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 { margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 { margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 { margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 { margin-left: -0.25rem !important; }
  .m-xl-n2 { margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 { margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 { margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 { margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 { margin-left: -0.5rem !important; }
  .m-xl-n3 { margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 { margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 { margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 { margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 { margin-left: -1rem !important; }
  .m-xl-n4 { margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 { margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 { margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 { margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 { margin-left: -1.5rem !important; }
  .m-xl-n5 { margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 { margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 { margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 { margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 { margin-left: -3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }

.text-monospace { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify { text-align: justify !important; }

.text-wrap { white-space: normal !important; }

.text-nowrap { white-space: nowrap !important; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }

@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }

@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }

@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }

.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.font-weight-light { font-weight: 300 !important; }

.font-weight-lighter { font-weight: lighter !important; }

.font-weight-normal { font-weight: 400 !important; }

.font-weight-bold { font-weight: 700 !important; }

.font-weight-bolder { font-weight: bolder !important; }

.font-italic { font-style: italic !important; }

.text-white { color: #ffffff !important; }

.text-primary { color: #ef7c00 !important; }

a.text-primary:hover, a.text-primary:focus { color: #a35400 !important; }

.text-secondary { color: #075e9c !important; }

a.text-secondary:hover, a.text-secondary:focus { color: #043253 !important; }

.text-success { color: #28a745 !important; }

a.text-success:hover, a.text-success:focus { color: #19692c !important; }

.text-info { color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus { color: #0f6674 !important; }

.text-warning { color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus { color: #ba8b00 !important; }

.text-danger { color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus { color: #a71d2a !important; }

.text-light { color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus { color: #cbd3da !important; }

.text-dark { color: #4b4b4b !important; }

a.text-dark:hover, a.text-dark:focus { color: #252525 !important; }

.text-body { color: #212529 !important; }

.text-muted { color: #6c757d !important; }

.text-black-50 { color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 { color: rgba(255, 255, 255, 0.5) !important; }

.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }

.text-decoration-none { text-decoration: none !important; }

.text-break { word-break: break-word !important; word-wrap: break-word !important; }

.text-reset { color: inherit !important; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

/* Slider */
.slick-loading .slick-list { background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face { font-family: "slick"; src: url("../lib/slick/fonts/slick.eot"); src: url("../lib/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../lib/slick/fonts/slick.woff") format("woff"), url("../lib/slick/fonts/slick.ttf") format("truetype"), url("../lib/slick/fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }

/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0px; font-size: 0px; cursor: pointer; background: transparent; color: transparent; top: 50%; -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%); transform: translate(0, -50%); padding: 0; border: none; outline: none; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }

[dir="rtl"] .slick-prev { left: auto; right: -25px; }

.slick-prev:before { content: "←"; }

[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }

[dir="rtl"] .slick-next { left: -25px; right: auto; }

.slick-next:before { content: "→"; }

[dir="rtl"] .slick-next:before { content: "←"; }

/* Dots */
.slick-dotted.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: -25px; list-style: none; display: block; text-align: center; padding: 0; margin: 0; width: 100%; }

.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0 5px; padding: 0; cursor: pointer; }

.slick-dots li button { border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0px; font-size: 0px; color: transparent; padding: 5px; cursor: pointer; }

.slick-dots li button:hover, .slick-dots li button:focus { outline: none; }

.slick-dots li button:hover:before, .slick-dots li button:focus:before { opacity: 1; }

.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "•"; width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before { color: black; opacity: 0.75; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-Light.eot"); src: url("../fonts/Montserrat/Montserrat-Light.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Light.svgMontserrat") format("svg"); font-weight: 300; font-style: normal; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-LightItalic.eot"); src: url("../fonts/Montserrat/Montserrat-LightItalic.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-LightItalic.woff") format("woff"), url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-LightItalic.svgMontserrat") format("svg"); font-weight: 300; font-style: italic; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-Regular.eot"); src: url("../fonts/Montserrat/Montserrat-Regular.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Regular.svgMontserrat") format("svg"); font-weight: normal; font-style: normal; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-Italic.eot"); src: url("../fonts/Montserrat/Montserrat-Italic.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Italic.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Italic.svgMontserrat") format("svg"); font-weight: normal; font-style: italic; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-Medium.eot"); src: url("../fonts/Montserrat/Montserrat-Medium.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Medium.svgMontserrat") format("svg"); font-weight: 500; font-style: normal; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-MediumItalic.eot"); src: url("../fonts/Montserrat/Montserrat-MediumItalic.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-MediumItalic.woff") format("woff"), url("../fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-MediumItalic.svgMontserrat") format("svg"); font-weight: 500; font-style: italic; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-Bold.eot"); src: url("../fonts/Montserrat/Montserrat-Bold.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Bold.svgMontserrat") format("svg"); font-weight: bold; font-style: normal; }

@font-face { font-family: "Montserrat"; src: url("../fonts/Montserrat/Montserrat-BoldItalic.eot"); src: url("../fonts/Montserrat/Montserrat-BoldItalic.eotiefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-BoldItalic.woff") format("woff"), url("../fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-BoldItalic.svgMontserrat") format("svg"); font-weight: bold; font-style: italic; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-Light.eot"); src: url("../fonts/Roboto/Roboto-Light.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Light.woff") format("woff"), url("../fonts/Roboto/Roboto-Light.ttf") format("truetype"), url("../fonts/Roboto/Roboto-Light.svgRoboto") format("svg"); font-weight: 300; font-style: normal; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-LightItalic.eot"); src: url("../fonts/Roboto/Roboto-LightItalic.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-LightItalic.woff") format("woff"), url("../fonts/Roboto/Roboto-LightItalic.ttf") format("truetype"), url("../fonts/Roboto/Roboto-LightItalic.svgRoboto") format("svg"); font-weight: 300; font-style: italic; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-Regular.eot"); src: url("../fonts/Roboto/Roboto-Regular.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto/Roboto-Regular.svgRoboto") format("svg"); font-weight: normal; font-style: normal; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-Italic.eot"); src: url("../fonts/Roboto/Roboto-Italic.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Italic.woff") format("woff"), url("../fonts/Roboto/Roboto-Italic.ttf") format("truetype"), url("../fonts/Roboto/Roboto-Italic.svgRoboto") format("svg"); font-weight: normal; font-style: italic; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-Medium.eot"); src: url("../fonts/Roboto/Roboto-Medium.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype"), url("../fonts/Roboto/Roboto-Medium.svgRoboto") format("svg"); font-weight: 500; font-style: normal; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-MediumItalic.eot"); src: url("../fonts/Roboto/Roboto-MediumItalic.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-MediumItalic.woff") format("woff"), url("../fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype"), url("../fonts/Roboto/Roboto-MediumItalic.svgRoboto") format("svg"); font-weight: 500; font-style: italic; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-Bold.eot"); src: url("../fonts/Roboto/Roboto-Bold.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto/Roboto-Bold.svgRoboto") format("svg"); font-weight: bold; font-style: normal; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-BoldItalic.eot"); src: url("../fonts/Roboto/Roboto-BoldItalic.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-BoldItalic.woff") format("woff"), url("../fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype"), url("../fonts/Roboto/Roboto-BoldItalic.svgRoboto") format("svg"); font-weight: bold; font-style: italic; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-Black.eot"); src: url("../fonts/Roboto/Roboto-Black.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Black.woff") format("woff"), url("../fonts/Roboto/Roboto-Black.ttf") format("truetype"), url("../fonts/Roboto/Roboto-Black.svgRoboto") format("svg"); font-weight: 900; font-style: normal; }

@font-face { font-family: "Roboto"; src: url("../fonts/Roboto/Roboto-BlackItalic.eot"); src: url("../fonts/Roboto/Roboto-BlackItalic.eotiefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-BlackItalic.woff") format("woff"), url("../fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype"), url("../fonts/Roboto/Roboto-BlackItalic.svgRoboto") format("svg"); font-weight: 900; font-style: italic; }

@font-face { font-family: "icomoon"; src: url("../fonts/Icomoon/fonts/icomoon.eot?c0c8z1"); src: url("../fonts/Icomoon/fonts/icomoon.eot?c0c8z1#iefix") format("embedded-opentype"), url("../fonts/Icomoon/fonts/icomoon.ttf?c0c8z1") format("truetype"), url("../fonts/Icomoon/fonts/icomoon.woff?c0c8z1") format("woff"), url("../fonts/Icomoon/fonts/icomoon.svg?c0c8z1#icomoon") format("svg"); font-weight: normal; font-style: normal; font-display: block; }

[class^="icomoon-"], [class*=" icomoon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: "icomoon" !important; speak: never; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icomoon-glanum:before { content: ""; }

.icomoon-Office_icon_recherche:before { content: ""; }

.icomoon-Office_icon_burger-menu:before { content: ""; }

.icomoon-Office_icon_facebook:before { content: ""; }

.icomoon-Office_icon_insta:before { content: ""; }

.icomoon-Office_icon_linkedin:before { content: ""; }

.icomoon-Office_icon_youtube:before { content: ""; }

.gridlock .row.bsrow { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; width: initial; }

@media screen and (min-width: 500px) { .gridlock .row.bsrow { width: initial; } }

@media screen and (min-width: 740px) { .gridlock .row.bsrow { width: initial; } }

@media screen and (min-width: 980px) { .gridlock .row.bsrow { width: initial; } }

@media screen and (min-width: 1220px) { .gridlock .row.bsrow { width: initial; } }

.gridlock .row.bsrow::before, .gridlock .row.bsrow::after { display: none; }

html, body { font-size: 16px; line-height: 1.2; color: #4b4b4b; overflow-x: hidden; }

h1:not(.stepper__screen__title), h2:not(.stepper__screen__title), h3:not(.stepper__screen__title), h4:not(.stepper__screen__title), h5:not(.stepper__screen__title), h6:not(.stepper__screen__title) { color: #4b4b4b; font-family: "Montserrat", sans-serif; font-stretch: normal; font-style: normal; }

p:not(.page-template-tpl_votre-sejour p), span:not(.page-template-tpl_votre-sejour span), ul:not(.page-template-tpl_votre-sejour ul), ol::not(.page-template-tpl_votre-sejour ol), li:not(.page-template-tpl_votre-sejour li), mark { font-stretch: normal; font-style: normal; font-weight: normal; line-height: 1.3; }

a:not(.page-template-tpl_votre-sejour a) { font-stretch: normal; font-style: normal; font-weight: normal; line-height: normal; }

strong { font-stretch: normal; font-style: normal; font-weight: bold; line-height: 1.5; }

h1:not(.stepper__screen__title) { font-family: "Montserrat", sans-serif; font-size: 3rem; line-height: 1.1; margin: 27px 0; font-weight: bold; text-transform: uppercase; }

@media (max-width: 1200px) { h1:not(.stepper__screen__title) { font-size: calc(1.425rem + 2.1vw) ; } }

h1:not(.stepper__screen__title).main__title-page { font-family: "Montserrat", sans-serif !important; font-size: 3rem; line-height: 1.1; margin: 27px 0; font-weight: bold; text-transform: uppercase; }

@media (max-width: 1200px) { h1:not(.stepper__screen__title).main__title-page { font-size: calc(1.425rem + 2.1vw) ; } }

h2:not(.stepper__screen__title) { font-family: "Roboto", sans-serif; font-size: 1.5rem; line-height: 1.1; margin: 6px 0 14px 0; }

@media (max-width: 1200px) { h2:not(.stepper__screen__title) { font-size: calc(1.275rem + 0.3vw) ; } }

h3:not(.page-template-tpl_votre-sejour h3) { font-family: "Roboto", sans-serif; font-size: 1.5rem; line-height: 1.1; margin: 6px 0 14px 0; }

@media (max-width: 1200px) { h3:not(.page-template-tpl_votre-sejour h3) { font-size: calc(1.275rem + 0.3vw) ; } }

h4:not(.page-template-tpl_votre-sejour h4) { font-family: "Roboto", sans-serif; font-size: 1.125rem; line-height: 1.1; margin: 3px 0 8px 0; }

h5:not(.page-template-tpl_votre-sejour h5) { font-family: "Roboto", sans-serif; font-size: 0.875rem; line-height: 1.1; margin: 3px 0 8px 0; }

h6:not(.page-template-tpl_votre-sejour h6) { font-family: "Roboto", sans-serif; font-size: 0.75rem; line-height: 1.1; margin: 3px 0 8px 0; }

p { font-family: "Roboto", sans-serif; font-size: 1rem; line-height: 1.3; margin-bottom: 16px; color: #4b4b4b; }

p  a { color: #ef7c00; transition: opacity ease-in-out 0.2s; }

p  a:hover, p  a:visited { text-decoration: underline; }

p  a:hover { opacity: 0.5; }

mark { font-family: "Roboto", sans-serif; color: #ffffff; background-color: #ef7c00; padding: 0 5px 0 5px; }

hr { display: block; width: 200px; margin-left: auto; margin-right: auto; margin-block-start: 30px; margin-block-end: 30px; }

figure { margin: 0; }

ul, ol { font-family: "Roboto", sans-serif; font-size: 1rem; padding-left: 0; }

ul  li, ol  li { font-family: "Roboto", sans-serif; margin-bottom: 16px; line-height: 1.5; position: relative; }

ul  li ul, ul  li ol, ol  li ul, ol  li ol { margin-top: 0.5em; }

ul:not(.slick-dots):not(.menu):not(.tags):not(.gm-navbar-nav):not(.gm-toolbar-socials-list):not(.gm-dropdown-menu) li { position: relative; font-size: 1rem; line-height: 1.5; margin-left: 16px; }

ol :not(.slick-dots):not(.menu):not(.tags):not(.gm-navbar-nav):not(.gm-toolbar-socials-list):not(.gm-dropdown-menu) { font-size: 1rem; padding-left: 0; }

ol :not(.slick-dots):not(.menu):not(.tags):not(.gm-navbar-nav):not(.gm-toolbar-socials-list):not(.gm-dropdown-menu) li { margin-left: 32px; padding-left: 16px; position: relative; }

#primaryFooter { width: 100%; margin-top: 0 !important; border-top: 0 !important; padding-top: 0 !important; text-align: center; }

@media screen and (min-width: 740px) { #primaryFooter { text-align: left; padding-top: 50px !important; } }

@media screen and (min-width: 740px) { #primaryFooter > .container { padding-bottom: 50px !important; } }

#primaryFooter .first-item { margin-bottom: 14px !important; }

@media screen and (min-width: 740px) { #primaryFooter .tablet-3:last-child { position: relative; }
  #primaryFooter .tablet-3:last-child #media_image-2 { position: absolute; top: 140px; left: 120px; } }

#primaryFooter .desktop-3 ul li { margin-bottom: 12px !important; }

#primaryFooter .desktop-3 ul li a { color: #4b4b4b !important; font-weight: 400 !important; transition: color ease-in-out 0.2s; }

#primaryFooter .desktop-3 ul li a:hover { color: #ef7c00 !important; }

#primaryFooter #media_image-5 img { margin-left: -15px; margin-top: -10px; width: 250px !important; }

#primaryFooter .footer-links { text-align: center; background-color: #8b8b8b !important; margin-top: 0 !important; margin-bottom: 0 !important; padding-bottom: 70px !important; }

@media (min-width: 992px) { #primaryFooter .footer-links { padding-bottom: 10px !important; } }

@media screen and (min-width: 740px) { #primaryFooter .footer-links { text-align: left; } }

#primaryFooter .footer-links .desktop-12 { display: flex; flex-direction: column; justify-content: space-between; }

@media screen and (min-width: 740px) { #primaryFooter .footer-links .desktop-12 { flex-direction: row; justify-content: space-between; } }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-4 .menu-item a { transition: opacity ease-in-out 0.2s; }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-4 .menu-item a:hover { opacity: 0.6; }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-4 .menu-item a span { font-size: 26px; }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-5 li { display: inline-block; }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-5 li:nth-child(-n + 6) a::after { display: inline-block; content: '|' !important; }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-5 li:last-child a::after { content: none !important; }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-5 li a { transition: opacity ease-in-out 0.2s; }

#primaryFooter .footer-links .desktop-12 #menu-widget-menu-5 li a:hover { opacity: 0.6; }

#primaryFooter .footer-links .wrap__widget { width: 100% !important; float: none !important; }

#primaryFooter .footer-links #nav_menu-12, #primaryFooter .footer-links #nav_menu-3 { width: auto !important; }

#primaryFooter .textwidget p { font-style: italic; font-weight: 500; color: #4b4b4b !important; font-size: 1rem; }

#primaryFooter #menu-widget-menu-4 { margin-top: 15px; text-align: center; justify-content: center; }

@media screen and (min-width: 740px) { #primaryFooter #menu-widget-menu-4 { margin-top: 0 !important; text-align: right; justify-content: flex-end; } }

#primaryFooter #menu-widget-menu-4 li { margin-left: 6px; }

#primaryFooter #menu-widget-menu-4 li a::after { content: none !important; }

#primaryFooter #menu-widget-menu-4 li a span { font-size: 2.1875rem; }

@media (max-width: 1200px) { #primaryFooter #menu-widget-menu-4 li a span { font-size: calc(1.34375rem + 1.125vw) ; } }

#primaryFooter .wrap__widget h2.first-item { margin-top: 0 !important; }

#primaryFooter .widget-wrapper { padding-top: 37px; padding-bottom: 37px; border-bottom: 2px solid #e2e0e2; }

#primaryFooter .widget-wrapper:last-child { border-bottom: none; }

@media screen and (min-width: 740px) { #primaryFooter .widget-wrapper { padding-top: 0; padding-bottom: 0; border-bottom: none; } }

#primaryFooter .widget-wrapper-2 { border-bottom: none; padding-bottom: 0; }

#primaryFooter .widget-wrapper-3 { padding-top: 0; }

.signatures { width: 100%; margin-left: 6px; margin-right: 6px; }

.signatures .signature_agence { transition: opacity ease-in-out 0.2s; }

.signatures .signature_agence:hover { opacity: 0.6; }

.slick-slider { margin-bottom: 90px; }

@media (min-width: 992px) { .slick-slider { margin-top: 0; margin-bottom: 0; } }

.slick-dots li button::before { font-size: 4.375rem; color: #8b8b8b; }

@media (max-width: 1200px) { .slick-dots li button::before { font-size: calc(1.5625rem + 3.75vw) ; } }

.button, .btn { display: inline-block; text-transform: uppercase; font-weight: bold; color: #ffffff; background-color: #ef7c00; border-radius: 32px; margin: 0; padding: 8px 16px 8px 16px; white-space: nowrap; font-family: "Roboto", sans-serif; font-weight: 900; transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s; }

.button::before, .button::after, .btn::before, .btn::after { display: none; }

.button:hover, .btn:hover { color: #ffffff; background-color: #075e9c; }

.content-btn + .content-btn { margin-top: 10px; }

.gm-navbar ul { margin: 0 !important; padding: 0 !important; }

.gm-navbar ul li { margin: 0 !important; padding: 0 !important; }

.gm-navbar ul li::before { display: none !important; }

.officeMenu.gm-navbar-sticky-toggle .gm-wrapper .gm-logo { padding: 0 !important; margin-bottom: 0 !important; }

.officeMenu.gm-navbar-sticky-toggle .gm-wrapper .gm-logo img { padding: 4px 0 4px 0 !important; }

.officeMenu .gm-wrapper { background-color: #ffffff; }

.officeMenu .gm-wrapper .gm-actions { position: absolute; display: block; right: 0; top: -25px; padding: 0; }

.officeMenu .gm-wrapper .gm-actions i { font-size: 25px; min-width: 25px; text-align: center; color: #ef7c00; transition: opacity ease-in-out 0.2s; }

.officeMenu .gm-wrapper .gm-actions i:hover { opacity: 0.7; }

.officeMenu .gm-wrapper .gm-toolbar { padding-top: 12px; }

.officeMenu .gm-wrapper .gm-toolbar-socials-list { margin: 0 !important; }

@media (min-width: 768px) { .officeMenu .gm-wrapper .gm-toolbar-socials-list { margin: 0 15px 0 0 !important; } }

@media (min-width: 992px) { .officeMenu .gm-wrapper .gm-toolbar-socials-list { margin: 0 30px 0 0 !important; } }

.officeMenu .gm-wrapper .gm-toolbar-socials-list li { margin: 0 4px 0 4px !important; }

.officeMenu .gm-wrapper .gm-toolbar-socials-list li:first-child { margin-left: 0 !important; }

.officeMenu .gm-wrapper .gm-toolbar-socials-list li:last-child { margin-right: 0 !important; }

.officeMenu .gm-wrapper .gm-logo { padding: 0 !important; margin-bottom: 0 !important; transition: opacity ease-in-out 0.2s; }

.officeMenu .gm-wrapper .gm-logo:hover { opacity: 0.6; }

@media (min-width: 768px) { .officeMenu .gm-wrapper .gm-logo { padding: 5px; margin-bottom: 20px !important; margin-right: 15px; margin-left: 30px; } }

.officeMenu .gm-wrapper .gm-logo img { padding: 4px 0 4px 0 !important; }

@media (min-width: 768px) { .officeMenu .gm-wrapper .gm-menu-btn { margin-right: 15px; } }

.officeMenu .gm-wrapper .gm-menu-btn i { color: #075e9c; }

.officeMenu .gm-wrapper .gm-menu-btn .gm-menu-btn__inner { font-size: 39px; transition: opacity ease-in-out 0.2s; }

.officeMenu .gm-wrapper .gm-menu-btn .gm-menu-btn__inner:hover { opacity: 0.6; }

.officeMenu .gm-wrapper .gm-navbar-nav .gm-menu-item--lvl-0 { margin: 0 12px 0 12px !important; }

@media (min-width: 992px) { .officeMenu .gm-wrapper .gm-navbar-nav .gm-menu-item--lvl-0:first-child { margin-left: 0 !important; }
  .officeMenu .gm-wrapper .gm-navbar-nav .gm-menu-item--lvl-0:last-child { margin-right: 0 !important; }
  .officeMenu .gm-wrapper .gm-navbar-nav .gm-menu-item--lvl-0 .menu-item .gm-menu-item__txt { font-weight: 400; } }

.officeMenu .gm-wrapper .gm-navbar-nav .gm-menu-item--lvl-0 .gm-menu-item__txt { font-weight: 500; }

.officeMenu .gm-wrapper .gm-navbar-nav .salon-link .gm-menu-item__txt { color: #075e9c; }

.officeMenu .gm-wrapper .gm-dropdown-menu { padding: 30px !important; }

.officeMenu .gm-wrapper .gm-main-menu-wrapper .mega-gm-dropdown .gm-dropdown-menu-wrapper { margin: 3px 0 0 auto !important; }

.officeMenu .gm-wrapper .gm-main-menu-wrapper .gm-menu-item__link { padding: 6px 0 6px 0; }

.officeMenu .gm-wrapper .gm-mega-menu__item__title { font-weight: 700 !important; }

.officeMenu .gm-wrapper .gm-mega-menu__item__title .gm-menu-item__txt { font-weight: 700 !important; font-size: 0.875rem; }

.officeMenu .gm-wrapper .gm-inner { box-shadow: none !important; border-bottom: 1px solid #e2e0e2 !important; }

.officeMenu .contactUsTxt { font-family: "Montserrat", sans-serif; font-size: 0.875rem; text-transform: uppercase; color: #4b4b4b; margin-right: 15px; }

.menu-item .gm-dropdown-menu-wrapper .gm-anchor, .menu-item .gm-dropdown-menu-wrapper .gm-menu-item__link { border-bottom: none !important; }

.gm-navigation-drawer--mobile.gm-navigation-drawer--right { transform: translate3d(320px, 0, 0) !important; right: 0 !important; }

.gm-navigation-drawer--mobile { width: 320px !important; max-width: 90% !important; font-size: 16px !important; }

.gm-navigation-drawer--mobile ul li, .gm-navigation-drawer--mobile ol li, .gm-navigation-drawer--mobile .gm-menu-item__txt { font-size: 16px !important; }

.gm-navigation-drawer--mobile .gm-mobile-action-area-wrapper { order: -1; }

.gm-navigation-drawer--mobile .gm-search { cursor: pointer; position: relative; display: flex; flex-direction: row; justify-content: center; align-items: center; background-color: #ef7c00; border-radius: 30px; padding: 8px 8px 8px 16px; width: 180px; max-width: 100%; white-space: nowrap; transition: opacity ease-in-out 0.2s; }

.gm-navigation-drawer--mobile .gm-search:hover { opacity: 0.6; }

.gm-navigation-drawer--mobile .gm-search i { position: absolute; top: 0; left: 0; bottom: 0; width: 40px; margin: 0 !important; font-size: 25px !important; color: #ffffff; transition: opacity ease-in-out 0.2s; }

.gm-navigation-drawer--mobile .gm-search i:hover { opacity: 0.6; }

.gm-navigation-drawer--mobile .gm-search i::before { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.gm-navigation-drawer--mobile .gm-search .gm-search__txt { color: #ffffff; margin: 0 0 0 10px !important; font-family: "Roboto", sans-serif; font-weight: 500; font-size: 16px !important; }

.gm-navigation-drawer--mobile .gm-anchor, .gm-navigation-drawer--mobile .gm-mega-menu__item__title { border-bottom: 1px solid #ef7c00 !important; }

.gm-navbar.gm-navbar--style-2 .gm-main-menu-wrapper:not(.gm-navigation-drawer--open) { display: none; }

.gm-navigation-drawer.gm-navigation-drawer--open { transform: translate3d(0, 0, 0) !important; }

.wrap__breadcrumb { margin: 30px 0 0 0 !important; border: 0 !important; padding-left: 0 !important; }

.gm-search__alpha { color: #ffffff !important; }

.gm-search__input { color: #ffffff !important; }

.info-bubble { display: block; position: fixed; z-index: 100; top: unset; right: unset; bottom: 0; left: 0; width: 100%; pointer-events: all; }

@media (min-width: 992px) { .info-bubble-container { position: absolute; max-width: 2015px; margin: 0 auto; top: 0; right: 0; bottom: 0; left: 0; pointer-events: none; } }

.info-bubble .bulle { position: relative; display: flex; border-radius: 0; border-top-left-radius: 40px; border-top-right-radius: 40px; padding: 10px 20px 10px 20px; height: 50px; width: 100%; max-width: 450px; margin: 0 auto; text-align: center; background-color: #075e9c; justify-content: center; align-items: center; transition: background-color ease-in-out 0.2s; }

@media (min-width: 2015px) { .info-bubble .bulle { text-align: center !important; } }

.info-bubble .bulle:hover { background-color: #05416b; }

.info-bubble .bulle-text { font-size: 0.875rem; color: #ffffff; font-family: Roboto; text-transform: uppercase; }

.info-bubble .bulle-text a { color: #ffffff; }

@media (min-width: 2015px) { .info-bubble .bulle-text { text-align: center !important; } }

.info-bubble .bulle::after { content: ''; background-image: url(/wp-content/themes/wp_loffice/interface2021/assets/img/Office_icon_avion.svg); width: 46px; height: 28px; position: absolute; display: inline-block; background-size: contain; background-repeat: no-repeat; left: 0; top: 0; transform: translate(20px, 5px); filter: drop-shadow(4px 4px 1px rgba(0, 0, 0, 0.3)); }

@media (min-width: 992px) { .info-bubble { position: absolute; z-index: 90; top: 60vh; right: -25px; bottom: unset; left: unset; width: unset; transition: top 300ms ease, right 300ms ease, width 300ms ease, height 300ms ease; }
  .info-bubble.isTop { right: 75px; transition: top 300ms ease, right 300ms ease, width 300ms ease, height 300ms ease; }
  .info-bubble.isTop .bulle { height: 150px; width: 150px; text-align: center; margin: 0; }
  .info-bubble.isTop .bulle-text { font-size: 18px; text-align: center; margin-right: 0; padding-top: 0; }
  .info-bubble .bulle { height: 120px; width: 120px; display: flex; text-align: right; border-radius: 50%; }
  .info-bubble .bulle-text { font-size: 12px; text-align: right; margin-right: 20px; padding-top: 10px; color: #ffffff; font-family: Roboto; text-align: right; text-transform: uppercase; } }

@media (min-width: 992px) and (min-width: 2015px) { .info-bubble .bulle-text { text-align: center !important; margin-right: 0px; } }

@media (min-width: 992px) { .info-bubble .bulle-text .line-1 { display: block; font-weight: bold; }
  .info-bubble .bulle-text .line-2 { display: block; }
  .info-bubble .bulle-text a { color: #ffffff; }
  .info-bubble .bulle::after { content: ''; background-image: url(/wp-content/themes/wp_loffice/interface2021/assets/img/Office_icon_avion.svg); width: 64px; height: 36px; transform: translate(5px, 5px); } }

.section-info-bubble-responsive { display: block; }

@media (min-width: 768px) { .section-info-bubble-responsive { display: none; } }

.section-info-bubble-responsive .bulle-responsive { position: fixed; z-index: 100; display: flex; text-align: center; bottom: 0; left: 50%; transform: translate(-50%, 0); margin: 0; border-radius: 0; border-top-left-radius: 40px; border-top-right-radius: 40px; padding: 10px 20px 10px 20px; height: 50px; width: 100%; max-width: 450px; }

.section-info-bubble-responsive .bulle-responsive-text { font-size: 0.875rem; color: #ffffff; font-family: Roboto; text-transform: uppercase; }

.section-info-bubble-responsive .bulle-responsive-text a { color: #ffffff; }

.section-info-bubble-responsive .bulle-responsive::after { content: ''; background-image: url(/wp-content/themes/wp_loffice/interface2021/assets/img/Office_icon_avion.svg); width: 46px; height: 28px; transform: translate(20px, 5px); }

.noTopTransition { transition: top 0ms ease, right 300ms ease, width 300ms ease, height 300ms ease !important; }

.glanum__link { display: flex; justify-content: center; color: #ffffff; margin-left: 8px !important; }

@media screen and (min-width: 1280px) { .glanum__link { justify-content: flex-start; } }

.glanum__link--logo { font-size: 19px; line-height: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }

.glanum__link--logo:hover { opacity: initial; animation-name: rotate; animation-duration: 2s; animation-iteration-count: infinite; animation-timing-function: linear; }

.glanum__link:hover { color: #ffffff !important; }

@keyframes rotate { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

.section-multimedia { position: relative; overflow: hidden; display: flex; justify-content: center; align-items: center; min-height: 600px; max-width: 100%; background-color: #e2e0e2; padding: 45px 0 45px 0; }

.section-multimedia__media { position: relative; width: 100%; }

.section-multimedia__media.image { padding-bottom: 88%; }

.section-multimedia__media.image img { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: block; width: 100%; max-width: 100%; height: auto; object-fit: cover; margin: 0 auto; }

.section-multimedia__media-videosGrid { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.section-multimedia__media-videosGrid .videoItem { flex: 0 0 100%; max-width: 100%; padding: 6px; }

@media (min-width: 768px) { .section-multimedia__media-videosGrid .videoItem { flex: 0 0 50%; max-width: 50%; } }

.section-multimedia__media-videosGrid .videoItem .youtube_player { width: 100% !important; max-width: 100% !important; height: 0 !important; overflow: hidden; padding-bottom: 56.25%; position: relative; }

.section-multimedia__media-videosGrid .videoItem .youtube_player iframe { left: 0; top: 0; height: 100%; width: 100%; position: absolute; }

.section-multimedia__content { position: relative; }

.section-multimedia__content::before { content: ''; display: none; position: absolute; height: 360px; width: 360px; top: 50%; right: 0; transform: translate(0, -20%); background: url("../img/elipse.svg"); background-position: center; background-repeat: no-repeat; background-size: contain; }

@media (min-width: 768px) { .section-multimedia__content::before { display: block; height: 260px; width: 260px; } }

@media (min-width: 992px) { .section-multimedia__content::before { height: 360px; width: 360px; } }

.section-multimedia__content.align-elispse-left::before { right: unset; left: 0; }

.section-multimedia__content.align-elispse-right::before { right: 0; left: unset; }

.section-multimedia__content.align-elispse-center::before { right: unset; left: 50%; transform: translate(-50%, 0); }

.content-text { z-index: 1; }

.content-text__heading { z-index: 1; position: relative; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; text-align: left; margin-bottom: 20px; }

.content-text__heading::after { content: ''; display: block; position: absolute; left: 0; bottom: 0; height: 4px; width: 95px; background-color: #ef7c00; }

@media (min-width: 768px) { .content-text__heading.align-left { text-align: left; }
  .content-text__heading.align-left::after { left: 0; bottom: 0; } }

@media (min-width: 768px) { .content-text__heading.align-right { text-align: right; justify-content: flex-end; align-items: flex-end; }
  .content-text__heading.align-right::after { left: unset; right: 0; bottom: 0; } }

@media (min-width: 768px) { .content-text__heading.align-center { text-align: center; justify-content: center; align-items: center; }
  .content-text__heading.align-center::after { left: 50%; bottom: 0; transform: translate(-50%, 0); } }

.content-text .two-lines, .content-text .one-line { margin: 0 0 20px 0; }

.content-text .two-lines + .tagline, .content-text .one-line + .tagline { margin: -20px 0 20px 0; }

.content-text .two-lines .first-line, .content-text .two-lines .second-line, .content-text .one-line .first-line, .content-text .one-line .second-line { display: block; line-height: 1.1; font-weight: normal; }

.content-text .two-lines .first-line, .content-text .one-line .first-line { font-size: 3rem; font-weight: bold; color: #4b4b4b; }

@media (max-width: 1200px) { .content-text .two-lines .first-line, .content-text .one-line .first-line { font-size: calc(1.425rem + 2.1vw) ; } }

.content-text .two-lines .second-line, .content-text .one-line .second-line { font-size: 2.25rem; font-weight: normal; color: #ef7c00; }

@media (max-width: 1200px) { .content-text .two-lines .second-line, .content-text .one-line .second-line { font-size: calc(1.35rem + 1.2vw) ; } }

.content-text .tagline { margin: 0 0 20px 0; line-height: 1.3; }

.content-text__txt { z-index: 1; font-size: 1rem; margin-bottom: 30px; }

.content-text__txt p { z-index: 1; position: relative; }

.content-tags .tags { display: block; margin: 0; padding: 10px; }

.content-tags .tags ul { display: flex; flex-direction: row; flex-wrap: wrap; margin: 0 -15px 0 -15px; padding: 0; }

.content-tags .tag { display: block; text-transform: uppercase; font-weight: bold; background-color: #ef7c00; border-radius: 32px; margin: 5px; padding: 0; white-space: nowrap; font-family: "Roboto", sans-serif; font-weight: 900; transition: background-color ease-in-out 0.2s; }

.content-tags .tag::before, .content-tags .tag::after { display: none; }

.content-tags .tag a { display: block; padding: 8px 16px 8px 16px; color: #ffffff; transition: color ease-in-out 0.2s; }

.content-tags .tag:hover { background-color: #075e9c; }

.content-btn { position: relative; z-index: 1; }

.section-info { padding: 60px 0; position: relative; }

@media (min-width: 992px) { .section-info { padding: 100px 0; } }

.section-info .first-line { text-align: center; }

.section-info .content-text__heading { margin-bottom: 16px; }

.section-info-title { align-items: center; max-width: 100%; margin: auto auto; }

@media (min-width: 768px) { .section-info-title { max-width: 100%; margin: auto auto 60px auto; } }

.section-info-text { max-width: 395px; margin: auto auto; }

@media (min-width: 768px) { .section-info-text { max-width: 520px; margin: auto auto 0 auto; } }

.section-info-text p { text-align: center; }

.section-info__items { max-width: 180px; margin: 0 auto; text-align: center; padding: 20px 0; }

@media (min-width: 576px) { .section-info__items { padding: 40px 0; } }

.section-info__media img { object-fit: contain; width: 120px; min-width: 120px; max-width: 100%; height: 120px; min-height: 120px; max-height: 120px; }

.section-info-description h3 { color: #075e9c; margin-bottom: 0; font-size: 1rem; font-family: "Montserrat", sans-serif; font-weight: 500; margin-bottom: 2px; }

.section-info-description p { font-family: "Roboto", sans-serif; font-weight: 500; font-size: 0.875rem; max-width: 200px; margin: 0 auto; color: #66686a; line-height: normal; }

.section-info__button { margin: 20px auto 0 auto; }

.section-info .content-text__heading::after { position: relative; }

.section-news { max-width: 950px; margin: 60px auto 60px auto; }

@media (min-width: 992px) { .section-news { margin: 80px auto 0 auto; } }

.section-news .content-text p { max-width: 395px; margin: auto auto; }

@media (min-width: 992px) { .section-news .content-text p { max-width: 520px; margin: auto auto 60px auto; } }

.section-news .content-text__heading { margin-bottom: 16px; }

.section-news .content-text__heading::after { position: relative; }

.section-news .content-text__excerpt { font-size: 1rem; line-height: 1.4; font-family: "Roboto", sans-serif; color: #4b4b4b; }

.section-news .content-text__excerpt p { font-size: 1rem; line-height: 1.4; font-family: "Roboto", sans-serif; color: #4b4b4b; max-width: 630px; margin: 0 auto; }

.section-news-button { margin: 30px auto 40px auto; }

@media (min-width: 992px) { .section-news-button { margin: 60px auto 80px auto; } }

.section-news__items { max-width: 300px; margin: auto auto; }

@media (min-width: 992px) { .section-news__items { padding: 0 30px; } }

.section-news__items .postTitle { font-size: 1rem; line-height: 1.3; text-transform: uppercase; color: #4b4b4b; margin-bottom: 0; font-family: "Montserrat", sans-serif; font-weight: 500; font-style: normal; transition: color ease-in-out 0.2s, opacity ease-in-out 0.2s; }

.section-news__items .postTitle:hover { color: #075e9c; opacity: 0.8; }

.section-news__items .date.content-text__heading { font-size: 1rem; font-family: "Montserrat", sans-serif; font-weight: 500; margin-bottom: 6px; color: #ef7c00; font-style: normal; text-transform: uppercase; }

.section-news__items .date.content-text__heading::after { margin-top: 4px; margin-bottom: 0; height: 2px; }

.section-news .slick-dots { bottom: -40px; }

.wrap__home-quality-container { position: relative; overflow: hidden; max-width: 100%; background-color: #f2f1ef; }

#wrap__home-quality { display: block; width: 1200px !important; max-width: 100% !important; margin: 0 auto; }
