.section-multimedia {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  max-width: 100%;
  background-color: $lightgrey;
  padding: 45px 0 45px 0;

  &__media {
    position: relative;
    width: 100%;
    &.image {
      padding-bottom: 88%;

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        object-fit: cover;
        margin: 0 auto;
      }
    }

    &-videosGrid {
      @include make-row();

      .videoItem {
        @include make-col(12);
        padding: 6px;

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }

        .youtube_player {
          width: 100% !important;
          max-width: 100% !important;
          height: 0 !important;
          overflow: hidden;
          padding-bottom: 56.25%;
          position: relative;

          iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
          }
        }
      }
    }
  }

  &__content {
    position: relative;

    &::before {
      content: '';
      display: none;
      position: absolute;
      height: 360px;
      width: 360px;
      top: 50%;
      right: 0;
      transform: translate(0, -20%);
      background: url('../img/elipse.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @include media-breakpoint-up(md) {
        display: block;
        height: 260px;
        width: 260px;
      }

      @include media-breakpoint-up(lg) {
        height: 360px;
        width: 360px;
      }
    }

    &.align-elispse-left {
      &::before {
        right: unset;
        left: 0;
      }
    }

    &.align-elispse-right {
      &::before {
        right: 0;
        left: unset;
      }
    }

    &.align-elispse-center {
      &::before {
        right: unset;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}

.content-text {
  z-index: 1;

  &__heading {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 20px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 95px;
      background-color: $primary;
    }

    &.align-left {
      @include media-breakpoint-up(md) {
        text-align: left;

        &::after {
          left: 0;
          bottom: 0;
        }
      }
    }

    &.align-right {
      @include media-breakpoint-up(md) {
        text-align: right;
        justify-content: flex-end;
        align-items: flex-end;

        &::after {
          left: unset;
          right: 0;
          bottom: 0;
        }
      }
    }

    &.align-center {
      @include media-breakpoint-up(md) {
        text-align: center;
        justify-content: center;
        align-items: center;

        &::after {
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  .two-lines,
  .one-line {
    margin: 0 0 20px 0;

    & + .tagline {
      margin: -20px 0 20px 0;
    }

    .first-line,
    .second-line {
      display: block;
      line-height: 1.1;
      font-weight: normal;
    }

    .first-line {
      @include font-size(48px);
      font-weight: bold;
      color: $text-color;
    }

    .second-line {
      @include font-size(36px);
      font-weight: normal;
      color: $primary;
    }
  }

  .tagline {
    margin: 0 0 20px 0;
    line-height: 1.3;
  }

  &__txt {
    z-index: 1;
    @include font-size(16px);
    margin-bottom: 30px;

    p {
      z-index: 1;
      position: relative;
    }
  }
}

.content-tags {
  .tags {
    display: block;
    margin: 0;
    padding: 10px;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -15px 0 -15px;
      padding: 0;
    }
  }

  .tag {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    background-color: $primary;
    border-radius: 32px;
    margin: 5px;
    padding: 0;
    white-space: nowrap;
    font-family: $Roboto;
    font-weight: 900;
    transition: background-color ease-in-out 0.2s;

    &::before,
    &::after {
      display: none;
    }

    a {
      display: block;
      padding: 8px 16px 8px 16px;
      color: $white;
      transition: color ease-in-out 0.2s;
    }

    &:hover {
      background-color: $secondary;
    }
  }
}

.content-btn {
  position: relative;
  z-index: 1;
}

.section-info {
  padding: 60px 0;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: 100px 0;
  }

  .first-line {
    text-align: center;
  }

  .content-text__heading {
    margin-bottom: 16px;
  }

  &-title {
    align-items: center;
    max-width: 100%;
    margin: auto auto;
    @include media-breakpoint-up(md) {
      max-width: 100%;
      margin: auto auto 60px auto;
    }
  }

  &-text {
    max-width: 395px;
    margin: auto auto;
    @include media-breakpoint-up(md) {
      max-width: 520px;
      margin: auto auto 0 auto;
    }

    p {
      text-align: center;
    }
  }

  &__items {
    max-width: 180px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;

    @include media-breakpoint-up(sm) {
      padding: 40px 0;
    }
  }

  &__media {
    img {
      object-fit: contain;
      width: 120px;
      min-width: 120px;
      max-width: 100%;
      height: 120px;
      min-height: 120px;
      max-height: 120px;
    }
  }

  &-description {
    h3 {
      color: $secondary;
      margin-bottom: 0;
      @include font-size(16px);
      font-family: $Montserrat;
      font-weight: 500;
      margin-bottom: 2px;
    }

    p {
      font-family: $Roboto;
      font-weight: 500;
      @include font-size(14px);
      max-width: 200px;
      margin: 0 auto;
      color: #66686a;
      line-height: normal;
    }
  }

  &__button {
    margin: 20px auto 0 auto;
  }

  .content-text {
    &__heading::after {
      position: relative;
    }
  }
}

.section-news {
  max-width: 950px;
  margin: 60px auto 60px auto;

  @include media-breakpoint-up(lg) {
    margin: 80px auto 0 auto;
  }
  .content-text {
    p {
      max-width: 395px;
      margin: auto auto;
      @include media-breakpoint-up(lg) {
        max-width: 520px;
        margin: auto auto 60px auto;
      }
    }

    &__heading {
      margin-bottom: 16px;
    }

    &__heading::after {
      position: relative;
    }

    &__excerpt {
      @include font-size(16px);
      line-height: 1.4;
      font-family: $Roboto;
      color: #4b4b4b;

      p {
        @include font-size(16px);
        line-height: 1.4;
        font-family: $Roboto;
        color: #4b4b4b;
        max-width: 630px;
        margin: 0 auto;
      }
    }
  }

  &-button {
    margin: 30px auto 40px auto;
    @include media-breakpoint-up(lg) {
      margin: 60px auto 80px auto;
    }
  }

  &__items {
    max-width: 300px;
    margin: auto auto;
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }

    .postTitle {
      @include font-size(16px);
      line-height: 1.3;
      text-transform: uppercase;
      color: #4b4b4b;
      margin-bottom: 0;
      font-family: $Montserrat;
      font-weight: 500;
      font-style: normal;
      transition: color ease-in-out 0.2s, opacity ease-in-out 0.2s;

      &:hover {
        color: $secondary;
        opacity: 0.8;
      }
    }
    .date.content-text {
      &__heading {
        @include font-size(16px);
        font-family: $Montserrat;
        font-weight: 500;
        margin-bottom: 6px;
        color: $primary;
        font-style: normal;
        text-transform: uppercase;

        &::after {
          margin-top: 4px;
          margin-bottom: 0;
          height: 2px;
        }
      }
    }
  }
  .slick-dots {
    bottom: -40px;
  }
}

.wrap__home-quality-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  background-color: #f2f1ef;
}

#wrap__home-quality {
  display: block;
  width: 1200px !important;
  max-width: 100% !important;
  margin: 0 auto;
}
