//colors
$white: #ffffff;
$black: #000000;
$dark: #4b4b4b;
$orange: #ef7c00;
$blue: #075e9c;
$lightgrey: #e2e0e2;

$primary: $orange;
$secondary: $blue;
$text-color: $dark;

//fonts

$Roboto: 'Roboto', sans-serif;
$Montserrat: 'Montserrat', sans-serif;

$fonts-path: '../fonts';
$icomoon-font-path: '../fonts/Icomoon/fonts';
$slick-font-path: '../lib/slick/fonts/';
$font-title: $Montserrat;
$font-text: $Roboto;

//Boostrap
$enable-responsive-font-sizes: true;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
) !default;
