@mixin font-face($font-path, $font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url('#{$font-path}/#{$font-name}/#{$file-name}.eot');
    src: url('#{$font-path}/#{$font-name}/#{$file-name}.eotiefix') format('embedded-opentype'), url('#{$font-path}/#{$font-name}/#{$file-name}.woff') format('woff'), url('#{$font-path}/#{$font-name}/#{$file-name}.ttf') format('truetype'), url('#{$font-path}/#{$font-name}/#{$file-name}.svg#{$font-name}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin center($position) {
  position: absolute;
  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  } @else if $position == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//Placeholder mixin
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin hidden {
  display: none;
  visibility: hidden;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  user-select: none;
  pointer-events: none;
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//ex: 16:9
@mixin responsive-ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + '%');
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

@mixin triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}
