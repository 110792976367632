#primaryFooter {
  width: 100%;
  margin-top: 0 !important;
  border-top: 0 !important;
  padding-top: 0 !important;
  text-align: center;

  @media screen and (min-width: 740px) {
    text-align: left;
    padding-top: 50px !important;
  }

  > .container {
    @media screen and (min-width: 740px) {
      padding-bottom: 50px !important;
    }
  }

  .first-item {
    margin-bottom: 14px !important;
  }

  .tablet-3 {
    @media screen and (min-width: 740px) {
      &:last-child {
        position: relative;
        #media_image-2 {
          position: absolute;
          top: 140px;
          left: 120px;
        }
      }
    }
  }

  .desktop-3 {
    ul {
      li {
        margin-bottom: 12px !important;
        a {
          color: #4b4b4b !important;
          font-weight: 400 !important;
          transition: color ease-in-out 0.2s;

          &:hover {
            color: $primary !important;
          }
        }
      }
    }
  }
  #media_image-5 {
    img {
      margin-left: -15px;
      margin-top: -10px;
      width: 250px !important;
    }
  }
  .footer-links {
    text-align: center;
    background-color: #8b8b8b !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 70px !important;

    @include media-breakpoint-up(lg) {
      padding-bottom: 10px !important;
    }

    @media screen and (min-width: 740px) {
      text-align: left;
    }

    .desktop-12 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (min-width: 740px) {
        flex-direction: row;
        justify-content: space-between;
      }

      #menu-widget-menu-4 {
        .menu-item {
          a {
            transition: opacity ease-in-out 0.2s;

            &:hover {
              opacity: 0.6;
            }

            span {
              font-size: 26px;
            }
          }
        }
      }

      #menu-widget-menu-5 {
        li {
          //display: block;

          //@media screen and (min-width: 740px) {
          display: inline-block;

          &:nth-child(-n + 6) {
            a {
              &::after {
                display: inline-block;
                content: '|' !important;
              }
            }
          }

          &:last-child {
            a {
              &::after {
                content: none !important;
              }
            }
          }
          //}

          a {
            transition: opacity ease-in-out 0.2s;

            &:hover {
              opacity: 0.6;
            }

            // &:after{
            //   display: none;
            // }
          }
        }
      }
    }

    .wrap__widget {
      width: 100% !important;
      float: none !important;
    }

    #nav_menu-12,
    #nav_menu-3 {
      width: auto !important;
    }
  }

  .textwidget {
    p {
      font-style: italic;
      font-weight: 500;
      color: #4b4b4b !important;
      @include font-size(16px);
    }
  }

  #menu-widget-menu-4 {
    margin-top: 15px;
    text-align: center;
    justify-content: center;

    @media screen and (min-width: 740px) {
      margin-top: 0 !important;
      text-align: right;
      justify-content: flex-end;
    }

    li {
      margin-left: 6px;
      a {
        &::after {
          content: none !important;
        }
        span {
          @include font-size(35px);
        }
      }
    }
  }

  .wrap__widget {
    h2 {
      &.first-item {
        margin-top: 0 !important;
      }
    }
  }

  .widget-wrapper {
    padding-top: 37px;
    padding-bottom: 37px;
    border-bottom: 2px solid #e2e0e2;

    &:last-child {
      border-bottom: none;
    }

    @media screen and (min-width: 740px) {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    &-2 {
      border-bottom: none;
      padding-bottom: 0;
    }

    &-3 {
      padding-top: 0;
    }
  }
}

.signatures {
  width: 100%;
  margin-left: 6px;
  margin-right: 6px;
  .signature_agence {
    transition: opacity ease-in-out 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }
}
