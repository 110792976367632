.gridlock {
  .row {
    &.bsrow {
      @include make-row;
      width: initial;

      @media screen and (min-width: 500px) {
        width: initial;
      }

      @media screen and (min-width: 740px) {
        width: initial;
      }

      @media screen and (min-width: 980px) {
        width: initial;
      }

      @media screen and (min-width: 1220px) {
        width: initial;
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
