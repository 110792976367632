html,
body {
  font-size: 16px;
  line-height: 1.2;
  color: $text-color;
  overflow-x: hidden;
}

h1:not(.stepper__screen__title),
h2:not(.stepper__screen__title),
h3:not(.stepper__screen__title),
h4:not(.stepper__screen__title),
h5:not(.stepper__screen__title),
h6:not(.stepper__screen__title) {
  color: $text-color;
  font-family: $font-title;
  font-stretch: normal;
  font-style: normal;
}

p:not(.page-template-tpl_votre-sejour p),
span:not(.page-template-tpl_votre-sejour span),
ul:not(.page-template-tpl_votre-sejour ul),
ol::not(.page-template-tpl_votre-sejour ol),
li:not(.page-template-tpl_votre-sejour li),
mark {
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3;
}

a:not(.page-template-tpl_votre-sejour a) {
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

strong {
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: 1.5;
}

h1:not(.stepper__screen__title) {
  font-family: $font-title;
  @include font-size(48px);
  line-height: 1.1;
  margin: 27px 0;
  font-weight: bold;
  text-transform: uppercase;

  &.main__title-page {
    font-family: $font-title !important;
    @include font-size(48px);
    line-height: 1.1;
    margin: 27px 0;
    font-weight: bold;
    text-transform: uppercase;
  }
}

h2:not(.stepper__screen__title) {
  font-family: $font-text;
  @include font-size(24px);
  line-height: 1.1;
  margin: 6px 0 14px 0;
}

h3:not(.page-template-tpl_votre-sejour h3) {
  font-family: $font-text;
  @include font-size(24px);
  line-height: 1.1;
  margin: 6px 0 14px 0;
}

h4:not(.page-template-tpl_votre-sejour h4) {
  font-family: $font-text;
  @include font-size(18px);
  line-height: 1.1;
  margin: 3px 0 8px 0;
}

h5:not(.page-template-tpl_votre-sejour h5) {
  font-family: $font-text;
  @include font-size(14px);
  line-height: 1.1;
  margin: 3px 0 8px 0;
}

h6:not(.page-template-tpl_votre-sejour h6) {
  font-family: $font-text;
  @include font-size(12px);
  line-height: 1.1;
  margin: 3px 0 8px 0;
}

p:not(.page-template-tpl_votre-sejour p) {
  font-family: $font-text;
  @include font-size(16px);
  line-height: 1.3;
  margin-bottom: 16px;
  color: $text-color;

  a {
    color: $primary;
    transition: opacity ease-in-out 0.2s;

    &:hover,
    &:visited {
      text-decoration: underline;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

mark {
  font-family: $font-text;
  color: $white;
  background-color: $primary;
  padding: 0 5px 0 5px;
}

hr {
  display: block;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-block-start: 30px;
  margin-block-end: 30px;
}

figure {
  margin: 0;
}

ul:not(.page-template-tpl_votre-sejour ul),
ol:not(.page-template-tpl_votre-sejour ol) {
  font-family: $font-text;
  @include font-size(16px);
  padding-left: 0;

  li {
    font-family: $font-text;
    margin-bottom: 16px;
    line-height: 1.5;
    position: relative;

    ul,
    ol {
      margin-top: 0.5em;
    }
  }
}

ul:not(.slick-dots):not(.menu):not(.tags):not(.gm-navbar-nav):not(.gm-toolbar-socials-list):not(.gm-dropdown-menu) {
  //list-style: none;

  li {
    position: relative;
    @include font-size(16px);
    line-height: 1.5;
    margin-left: 16px;
  }
}

ol:not(.page-template-tpl_votre-sejour ol) {
  &:not(.slick-dots):not(.menu):not(.tags):not(.gm-navbar-nav):not(.gm-toolbar-socials-list):not(.gm-dropdown-menu) {
    @include font-size(16px);
    padding-left: 0;

    li {
      margin-left: 32px;
      padding-left: 16px;
      position: relative;
    }
  }
}
