.button,
.btn {
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  color: $white;
  background-color: $primary;
  border-radius: 32px;
  margin: 0;
  padding: 8px 16px 8px 16px;
  white-space: nowrap;
  font-family: $Roboto;
  font-weight: 900;
  transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    color: $white;
    background-color: $secondary;
  }
}

.content-btn + .content-btn {
  margin-top: 10px;
}
