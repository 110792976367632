.glanum {
  &__link {
    display: flex;
    justify-content: center;
    color: $white;
    margin-left: 8px !important;

    @media screen and (min-width: 1280px) {
      justify-content: flex-start;
    }

    &--logo {
      font-size: 19px;
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: initial;
        animation-name: rotate;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    &:hover {
      color: $white !important;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
