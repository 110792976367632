@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?c0c8z1');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?c0c8z1#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?c0c8z1') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?c0c8z1') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?c0c8z1##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-glanum {
  &:before {
    content: $icomoon-glanum; 
  }
}
.icomoon-Office_icon_recherche {
  &:before {
    content: $icomoon-Office_icon_recherche; 
  }
}
.icomoon-Office_icon_burger-menu {
  &:before {
    content: $icomoon-Office_icon_burger-menu; 
  }
}
.icomoon-Office_icon_facebook {
  &:before {
    content: $icomoon-Office_icon_facebook; 
  }
}
.icomoon-Office_icon_insta {
  &:before {
    content: $icomoon-Office_icon_insta; 
  }
}
.icomoon-Office_icon_linkedin {
  &:before {
    content: $icomoon-Office_icon_linkedin; 
  }
}
.icomoon-Office_icon_youtube {
  &:before {
    content: $icomoon-Office_icon_youtube; 
  }
}

