//@include font-face($fonts-path, $font-title, 'font-title-italic', 300, italic);

//MONTSERRAT
@include font-face($fonts-path, 'Montserrat', 'Montserrat-Light', $weight: 300, $style: normal);
@include font-face($fonts-path, 'Montserrat', 'Montserrat-LightItalic', $weight: 300, $style: italic);
@include font-face($fonts-path, 'Montserrat', 'Montserrat-Regular', $weight: normal, $style: normal);
@include font-face($fonts-path, 'Montserrat', 'Montserrat-Italic', $weight: normal, $style: italic);
@include font-face($fonts-path, 'Montserrat', 'Montserrat-Medium', $weight: 500, $style: normal);
@include font-face($fonts-path, 'Montserrat', 'Montserrat-MediumItalic', $weight: 500, $style: italic);
@include font-face($fonts-path, 'Montserrat', 'Montserrat-Bold', $weight: bold, $style: normal);
@include font-face($fonts-path, 'Montserrat', 'Montserrat-BoldItalic', $weight: bold, $style: italic);

//ROBOTO
@include font-face($fonts-path, 'Roboto', 'Roboto-Light', $weight: 300, $style: normal);
@include font-face($fonts-path, 'Roboto', 'Roboto-LightItalic', $weight: 300, $style: italic);
@include font-face($fonts-path, 'Roboto', 'Roboto-Regular', $weight: normal, $style: normal);
@include font-face($fonts-path, 'Roboto', 'Roboto-Italic', $weight: normal, $style: italic);
@include font-face($fonts-path, 'Roboto', 'Roboto-Medium', $weight: 500, $style: normal);
@include font-face($fonts-path, 'Roboto', 'Roboto-MediumItalic', $weight: 500, $style: italic);
@include font-face($fonts-path, 'Roboto', 'Roboto-Bold', $weight: bold, $style: normal);
@include font-face($fonts-path, 'Roboto', 'Roboto-BoldItalic', $weight: bold, $style: italic);
@include font-face($fonts-path, 'Roboto', 'Roboto-Black', $weight: 900, $style: normal);
@include font-face($fonts-path, 'Roboto', 'Roboto-BlackItalic', $weight: 900, $style: italic);

//ICOMOON
@import '../../fonts/Icomoon/style.scss';
