.info-bubble {
  display: block;
  position: fixed;
  z-index: 100;
  top: unset;
  right: unset;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: all;

  &-container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      max-width: 2015px;
      margin: 0 auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
  }

  .bulle {
    position: relative;
    display: flex;
    border-radius: 0;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 10px 20px 10px 20px;
    height: 50px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    background-color: $secondary;
    justify-content: center;
    align-items: center;
    transition: background-color ease-in-out 0.2s;

    @media (min-width: 2015px) {
      text-align: center !important;
    }

    &:hover {
      background-color: darken($secondary, 10%);
    }

    &-text {
      @include font-size(14px);
      color: $white;
      font-family: Roboto;
      text-transform: uppercase;
      a {
        color: $white;
      }

      @media (min-width: 2015px) {
        text-align: center !important;
      }
    }

    &::after {
      content: '';
      background-image: url(/wp-content/themes/wp_loffice/interface2021/assets/img/Office_icon_avion.svg);
      width: 46px;
      height: 28px;
      position: absolute;
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
      transform: translate(20px, 5px);
      filter: drop-shadow(4px 4px 1px rgba(0, 0, 0, 0.3));
    }
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: 90;
    top: 60vh;
    right: -25px;
    bottom: unset;
    left: unset;
    width: unset;
    transition: top 300ms ease, right 300ms ease, width 300ms ease, height 300ms ease;

    &.isTop {
      right: 75px;
      transition: top 300ms ease, right 300ms ease, width 300ms ease, height 300ms ease;

      .bulle {
        height: 150px;
        width: 150px;
        text-align: center;
        margin: 0;

        &-text {
          font-size: 18px;
          text-align: center;
          margin-right: 0;
          padding-top: 0;
        }
      }
    }

    .bulle {
      height: 120px;
      width: 120px;
      display: flex;
      text-align: right;
      border-radius: 50%;

      &-text {
        font-size: 12px;
        text-align: right;
        margin-right: 20px;
        padding-top: 10px;
        color: $white;
        font-family: Roboto;
        text-align: right;
        text-transform: uppercase;

        @media (min-width: 2015px) {
          text-align: center !important;
          margin-right: 0px;
        }

        .line-1 {
          display: block;
          font-weight: bold;
        }

        .line-2 {
          display: block;
        }

        a {
          color: $white;
        }
      }

      &::after {
        content: '';
        background-image: url(/wp-content/themes/wp_loffice/interface2021/assets/img/Office_icon_avion.svg);
        width: 64px;
        height: 36px;
        transform: translate(5px, 5px);
      }
    }
  }
}

//Reponsive
.section-info-bubble-responsive {
  display: block;
  @include media-breakpoint-up(md) {
    display: none;
  }

  .bulle-responsive {
    position: fixed;
    z-index: 100;
    display: flex;
    text-align: center;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
    border-radius: 0;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 10px 20px 10px 20px;
    height: 50px;
    width: 100%;
    max-width: 450px;
    &-text {
      @include font-size(14px);
      color: $white;
      font-family: Roboto;
      text-transform: uppercase;
      a {
        color: $white;
      }
    }
    &::after {
      content: '';
      background-image: url(/wp-content/themes/wp_loffice/interface2021/assets/img/Office_icon_avion.svg);
      width: 46px;
      height: 28px;
      transform: translate(20px, 5px);
    }
  }
}

.noTopTransition {
  transition: top 0ms ease, right 300ms ease, width 300ms ease, height 300ms ease !important;
}
