$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icomoon-glanum: "\e906";
$icomoon-Office_icon_recherche: "\e905";
$icomoon-Office_icon_burger-menu: "\e900";
$icomoon-Office_icon_facebook: "\e901";
$icomoon-Office_icon_insta: "\e902";
$icomoon-Office_icon_linkedin: "\e903";
$icomoon-Office_icon_youtube: "\e904";

